import { render, staticRenderFns } from "./cars.vue?vue&type=template&id=6b5fd06a&scoped=true&"
import script from "./cars.vue?vue&type=script&lang=js&"
export * from "./cars.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b5fd06a",
  null
  
)

export default component.exports