<template>
  <div class="box-body">
    <card-items :data_search="data_search"
                :filters_query="filter_cars"
                :relations="relations"
                component="car-item"
                item_classes="col-md-6 col-sm-12 col-xs-12 mb-4"
                api_resource="/cars">
    </card-items>
  </div>
</template>

<script>
import Vue from "vue";
import CardItems from './../../../components/pagination/card-items';

Vue.component('car-item', (resolve) => {
  return require(['./../../cars/item'], resolve);
});

export default {
  name: 'client-cars',

  components: {
    CardItems,
  },

  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    data_search: {
      type: String,
      required: false
    }
  },

  data: () => ({
    relations: [
      'branch',
      'equipments',
      'specification.gearbox',
      'specification.fuel',
      'specification.color',
      'specification.transmission',
      'seo',
      'creator',
      'editor',
    ],

    filter_cars: {
      owner_id: null,
    },
  }),

  async created() {
    try {
      this.filter_cars.owner_id = this.clientId;
      this.updateFilters();
    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {},

  methods: {
    updateFilters() {
      this.EventBus.$emit('card-filter-changed');
    },
  },
}
</script>

<style scoped>

</style>
